import React from "react";
import { Link } from "react-router-dom";

export const ButtonContent = ({ btnName, btnphone, btnStyle }) => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return btnphone ? (
    <a
      className={`
                boton
                my-3
                ${btnStyle}
                px-6
                py-4
                w-52
                rounded-lg
                uppercase text-[18px]
            `}
      href={`tel:+1${btnphone}`}
    >
      <span className="uppercase text-[18px]">{btnphone}</span>
    </a>
  ) : (
    // estilo de boton: one, two, three
    <Link
      className={`
                boton
                my-3
                ${btnStyle ? btnStyle : "one"}
                px-4
                py-4
                w-52
                rounded-lg
                uppercase text-[18px]
            `}
      to={`/${btnName === "view all services" ? "services" : "contact"}`}
      onClick={goToTop}
    >
      <span className="uppercase text-[18px]">
        {btnName ? btnName : "free estimate"}
      </span>
    </Link>
  );
};
