import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <div className="flex justify-center items-center pb-5">
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
              alt="Google My Bussiness"
            >
              <img src={rpdata?.gmb?.img} alt="Google My Bussiness" className="md:w-[270px] w-[200px] md:h-[250px] h-[200px] object-cover  relative" loading="lazy" intrinsicsize="800x600"/>
            </a>
          ) : null}
        </div>
        {rpdata?.dbSocialMedia?.redes?.[0].url.length > 0 ||
        rpdata?.dbSocialMedia?.directorios?.[0].url.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}
        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}
        >
          {rpdata?.dbSocialMedia?.directorios?.[0].url.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"a
                      alt={item.name}
                    >
                      <img src={item.image} alt={item.name} className="w-[350px] h-[80px] object-contain" loading="lazy"/>
                    </a>
                  </div>
                );
              })
            : null}
        </div>
        <div className={`flex flex-wrap justify-center items-center gap-4`}>
          {rpdata?.dbSocialMedia?.redes?.[0].url.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      alt={item.name}
                    >
                      <img src={item.image} alt={item.name} className="w-[300px] h-[80px] object-contain" loading="lazy"/>
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default Directories;
